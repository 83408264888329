@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu a::after {
  transform: rotate(-90deg);
  position: absolute;
  right: 6px;
  top: .8em;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-left: 0;
  margin-right: 0;
}

@media (max-width: 991px) {
  .dropdown-submenu .dropdown-menu {
    margin-top: .5rem;
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .navbar-dark .dropdown-menu,
  .navbar-dark .dropdown-divider {
    background-color: transparent !important;
    border-color: rgba(255, 255, 255, 0.5);
  }

  .navbar-dark .dropdown-item {
    background-color: transparent !important;
    color: rgba(255, 255, 255, 0.5);
  }

  .navbar-dark .dropdown-item:hover {
    color: rgba(255, 255, 255, 0.75);
  }

  .navbar-dark .dropdown-item:focus {
    color: white;
  }
}

table.table-custom td,
table.table-custom th {
  vertical-align: middle;
}

.ul2 {
  list-style-type: disc;
  columns: 2;
  list-style-position: inside;
}

.select2-selection__rendered {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  padding-left: 0.75em !important;
}

.custom-file-label::after {
  content: "Buscar";
}

/*# sourceMappingURL=app.css.map */